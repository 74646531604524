export default {
  title: 'فروش',

  proformaInvoices: {
    title: 'پیش فاکتور'

  },

  invoices: {
    title: 'فاکتورهای فروش',

    labels: {
      id: 'شناسه فاکتور',
      user: 'طرف حساب',
      fullName: 'نام کامل',
      address: 'آدرس',
      email: 'ایمیل',
      registryDate: 'تاریخ ثبت',
      orderDate: 'تاریخ سفارش',
      phoneNumber: 'شماره تماس',
      pre: 'پیش',
      status: 'وضعیت',
      gift: 'هدیه',
      store: 'انبار',
      note: 'توضیحات',
      cooperation: 'همکاری',
      availableProduct: 'کالاهای موجود',
      allProduct: 'همه کالاها',

      productPrice: 'قیمت کل کالاها',
      servicePrice: 'قیمت کل خدمات',
      tax: 'مالیات',
      totalDiscount: 'تخفیف کل',
      totalVatExc: 'مبلغ خالص (بدون مالیات)',
      totalPrice: 'قیمت کل',
      totalVatInc: 'مبلغ خالص',
      grandTotal: 'مبلغ قابل پرداخت',
      totalCount: 'تعداد',
      totalVat: 'مالیات',
      total: 'مجموع',
      shipping: 'حمل و نقل',
      totalGrand: 'مجموع نهایی',
      required: '(اجباری)',
      TRN: 'شماره مالیاتی: ',
      vatExc: 'بدون مالیات',
      vatInc: 'با مالیات',
      vatContainTextBefore: 'شامل ',
      vatContainTextAfter: 'مالیات',
      attachmentLabel: 'پیوست {key}',
      services: {
        name: 'نام خدمت',
        quantity: 'تعداد',
        price: 'قیمت'
      }
    },

    placeholders: {
      id: 'اتوماتیک'
    },

    prompts: {
      receive: {
        title: 'دریافت وجه از مشتری',
        dynamicTitle: 'دریافت وجه از {name}',

        subject: 'فاکتور فروش شماره {id}',

        actions: {
          payment: 'پرداخت',
          cancel: 'انصراف و مشاهده پیش فاکتور'
        }
      },

      confirm: {
        title: 'تایید افزودن فاکتور فروش',

        actions: {
          payment: 'تایید',
          cancel: 'انصراف'
        }
      },

      sendMail: {
        title: 'ارسال فاکتور فروش به ایمیل'
      },

      address: {
        insert: {
          title: '',
          dynamicTitle: 'افزودن آدرس جدید برای {name}'
        },

        list: {
          title: '',
          dynamicTitle: 'لیست آدرس های {name}'
        }
      },

      users: {
        list: {
          title: 'لیست کاربران'
        },
        insert: {
          title: 'افزودن کاربر جدید'
        }
      },

      noteDialog: {
        title: 'توضیحات مشتری برای فاکتور'
      }
    },

    actionTypes: {
      present: 'حضوری',
      tel: 'تلفنی',
      internet: 'اینترنتی'
    },

    processTypes: {
      proformaInvoices: 'پیش فاکتور فروش',
      invoices: 'فاکتور فروش',
      storeExit: 'خروج انبار',
      incomplete: 'ناتمام'
    },

    notifications: {
      isExists: {
        message: 'این مقدار در لیست وجود دارد',
        dynamicMessage: '{name} در لیست وجود دارد'
      },

      increaseCount: {
        message: 'تعداد این کالا اضافه شد',
        dynamicMessage: 'تعداد { name } اضافه شد'
      },

      productNotFount: 'کالایی با این بارکد پیدا نشد',
      quantity: 'موجودی کالاها کافی نیست!',
      dynamicQuantity: 'موجودی کالای {name} کافی نیست!',
      activeRefundInvoiceExists: 'برای این فاکتور، فاکتور مرجوع {invoices} ثبت شده اند. ابتدا وضعیت این فاکتورها را به پیش فاکتور تغییر دهید | برای این فاکتور، فاکتورهای مرجوع {invoices} ثبت شده اند. ابتدا وضعیت این فاکتورها را به پیش فاکتور تغییر دهید',
      lowBalance: 'پیش فاکتور ثبت شد اما به دلیل کمبود اعتبار مشتری جهت تکمیل فاکتور نیاز به دریافت وجه می باشد!',
      discountIsBigger: 'تخفیف کالای {name} بیشتراز مبلغ کل کالا است!',

      insert: {
        success: 'فاکتور با موفقیت ثبت شد',
        error: 'ثبت فاکتور با خطا همراه شد!'
      },

      edit: {
        success: 'فاکتور با موفقیت ویرایش شد',
        error: 'ویرایش فاکتور با خطا همراه شد!'
      },

      delete: {
        success: 'فاکتور با موفقیت حذف شد',
        error: 'حذف فاکتور فروش با خطا همراه شد'
      },

      restore: {
        success: 'فاکتور با موفقیت بازگردانی شد',
        error: 'بازگردانی فاکتور فروش با خطا همراه شد'
      },

      refreshStatus: {
        success: 'وضعیت فاکتور با موفقیت برگردانده شد',
        error: 'برگرداندن وضعیت فاکتور با خطا شد'
      },

      parseError: {
        user: 'طرف حساب انتخاب نشده است!',
        accountSide: 'طرف حساب انتخاب نشده است!',
        lines: 'هیچ کالایی انتخاب نشده است!',
        address: 'آدرس به صورت صحیح وارد نشده است!',
        storeroom: 'انبار انتخاب نشده است!',
        unitPrice: 'مبلغ کالا وارد نشده است!',
        invoiceNumber: 'این شماره فاکتور از قبل انتخاب شده است!',
        quantity: 'تعداد کالای انتخاب شده معتبر نیست!',
        discount: 'تخفیف وارد شده معتبر نیست!',
        taxRate: 'مالیات وارد شده معتبر نیست!',
        dynamicDiscount: 'تخفیف وارد شده در سطر {index} معتبر نیست!',
        noProductEnter: 'هیچ کالای انتخاب نشده است!',
        cooperationId: 'شخصی به عنوان همکار فروش انتخاب نشده است',
        cooperationPrice: 'مبلغ همکاری وارد شده معتبر نیست'
      }
    },

    confirmations: {
      delete: {
        title: 'تائیدیه حذف فاکتور فروش',
        body: 'در صورت حذف فاکتور فروش تمامی اسناد و حواله های انبار مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
      },
      forceDelete: {
        title: 'تائیدیه حذف فاکتور فروش',
        body: 'در صورت حذف فاکتور فروش اطلاعات فاکتور قابل برگشت نیست. آیا اطمینان دارید؟'
      },
      preInvoiceWithoutQuantity: {
        title: 'تائیدیه پیش فاکتور فروش',
        body: 'تعدادی از کالاهای انتخاب شده در انبار موجود نیستند. آیا از تایید فاکتور به عنوان پیش فاکتور اطمینان دارید؟'
      },
      forceUpdate: {
        title: 'تائیدیه ویرایش فاکتور فروش',
        body: 'با ویرایش این فاکتور تمامی اسناد و حواله های انبار ثبت شده، حذف می شوند و سپس اسناد و حواله های جدید صادر می شوند. آیا از ادامه فرآیند اطمینان دارید؟'
      }
    },

    list: {
      title: 'فاکتورهای فروش',

      table: {
        header: {
          id: 'شماره فاکتور',
          receivedId: 'شماره فاکتور مرجع',
          creator: 'ایجادکننده',
          user: 'طرف حساب',
          province: 'استان',
          country: 'کشور',
          date: 'تاریخ',
          totalDiscount: 'تخفیف',
          totalVat: 'مالیات',
          shipping: 'حمل و نقل',
          finalPrice: 'مبلغ نهایی',
          type: 'نوع فروش',
          status: 'وضعیت',
          restore: 'بازگردانی',
          delete: 'حذف'
        },

        filters: {
          type: {
            all: 'همه',
            present: 'فروش حضوری',
            tel: 'فروش تلفنی',
            internet: 'فروش اینترنتی'
          },
          status: {
            all: 'همه',
            proformaInvoices: 'پیش فاکتور فروش',
            invoices: 'فاکتور فروش',
            storeExit: 'خروج انبار',
            incomplete: 'ناتمام'
          }
        }
      },

      actionTypes: {
        present: 'فروش حضوری',
        tel: 'فروش تلفنی',
        internet: 'فروش اینترنتی'
      }
    },

    insert: {
      title: 'افزودن فاکتور فروش',

      telTypeTitle: 'افزودن فاکتور فروش تلفنی',
      presentTypeTitle: 'افزودن فاکتور فروش حضوری',

      table: {
        header: {
          rowNumber: 'ردیف',
          barcode: 'بارکد',
          name: 'کالا / خدمت',
          variant: 'متغیر',
          storeroom: 'انبار',
          count: 'تعداد',
          tax: 'مالیات',
          unitTax: 'مالیات واحد',
          unitPrice: 'مبلغ واحد',
          totalPrice: 'مبلغ کل',
          discount: 'تخفیف',
          unitDiscount: 'تخفیف واحد',
          netUnit: 'خالص واحد',
          subTotal: 'مبلغ کل',
          finalPrice: 'مبلغ نهایی',
          delete: 'حذف'
        }
      }
    },

    invoice: {
      title: 'فاکتور فروش',
      dynamicTitle: '{pre} فاکتور فروش {type} شماره {id}',
      dynamicTitleWithoutId: 'فاکتور فروش {type}',

      labels: {
        payment: 'پرداخت',
        cancel: 'انصراف'
      },

      table: {
        header: {
          rowNumber: 'ردیف',
          barcode: 'بارکد',
          name: 'کالا / خدمت',
          variant: 'متغیر',
          storeroom: 'انبار',
          count: 'تعداد',
          tax: 'مالیات',
          unitTax: 'مالیات واحد',
          unitPrice: 'مبلغ واحد',
          totalPrice: 'مبلغ کل',
          discount: 'تخفیف',
          gift: 'هدیه',
          unitDiscount: 'تخفیف واحد',
          netUnit: 'خالص واحد',
          subTotal: 'مبلغ کل',
          finalPrice: 'مبلغ نهایی',
          delete: 'حذف'
        }
      },

      validators: {
        user: 'تامین کننده انتخاب نشده است!',
        products: 'هیچ کالایی انتخاب نشده است!',
        store: 'انبار انتخاب نشده است!',
        unitPrice: 'مبلغ کالا وارد نشده است!',
        invoiceNumber: 'این شماره فاکتور از قبل انتخاب شده است!',
        quantity: 'موجودی کالاها کافی نیست!',
        lowPrice: 'پیش فاکتور ثبت شد اما به دلیل کمبود اعتبار مشتری جهت تکمیل فاکتور نیاز به دریافت وجه می باشد!'
      },

      notifications: {
        complete: {
          success: 'فاکتور با موفقیت ثبت شد',
          error: 'ثبت فاکتور با خطا همراه شد!'
        },

        sendMail: {
          success: 'فاکتور با موفقیت به ایمیل ارسال شد',
          error: 'ارسال فاکتور به ایمیل با خطا همراه شد!'
        },

        storeExit: {
          error: 'حواله خروج انبار فاکتور فروش ثبت نشده است'
        }
      },

      actionTypes: {
        present: 'حضوری',
        tel: 'تلفنی',
        internet: 'اینترنتی'
      },

      actions: {
        print: 'پرینت',
        back: 'بازگشت'
      },

      logs: {
        title: 'لاگ های فاکتور فروش',
      },

      print: {
        title: 'فاکتور فروش',
        preInvoice: 'پیش فاکتور',
        taxInvoice: 'فاکتور مالیاتی',

        labels: {
          date: 'تاریخ',
          documentNumber: 'شماره',
          TRN: 'شماره مالیاتی',
          printNumber: 'شماره',
          note: 'توضیحات',
          shipTo: 'گیرنده',
          shipFrom: 'فرستنده',
          phone: 'تلفن',
          address: 'آدرس',
          total: 'مجموع',
          beforeTax: 'قبل مالیات',
          tax: 'مالیات',
          totalTax: 'مالیات کل',
          afterTax: 'بعد مالیات',
          totalDiscount: 'تخفیف کل',
          totalPrice: 'قیمت کل',
          finalPrice: 'قیمت نهایی',
          shipping: 'حمل و نقل',
          bankInfo: 'اطلاعات بانک',
          accountName: 'نام حساب',
          bankName: 'نام بانک',
          iban: 'شماره شبا',
          cardNumber: 'شماره کارت',
          swiftCode: 'شناسه بانک',
          vatExc: 'بدون مالیات',
          vatInc: 'با مالیات'
        },

        table: {
          header: {
            row: 'ردیف',
            code: 'بارکد',
            description: 'توضیحات',
            count: 'تعداد',
            tax: 'مالیات',
            unitPrice: 'واحد',
            unitPriceVatExc: 'واحد (بدون مالیات)',
            netPrice: 'خالص',
            totalPrice: 'کل',
            discount: 'تخفیف',
            unitDiscount: 'تخفیف واحد',
            finalPrice: 'مبلغ نهایی',
            subTotal: 'مبلغ نهایی'
          }
        }
      }
    },

    edit: {
      title: 'ویرایش فاکتور فروش',
      dynamicTitle: 'ویرایش فاکتور فروش {type}',

      labels: {
        first: 'اول',
        second: 'دوم'
      },

      table: {
        header: {
          rowNumber: 'ردیف',
          barcode: 'بارکد',
          name: 'کالا',
          variant: 'متغیر',
          store: 'انبار',
          count: 'تعداد',
          unitPrice: 'مبلغ واحد',
          totalPrice: 'مبلغ کل',
          discount: 'تخفیف',
          finalPrice: 'مبلغ نهایی',
          delete: 'حذف'
        }
      },

      actionTypes: {
        present: 'حضوری',
        tel: 'تلفنی',
        internet: 'اینترنتی'
      }
    },

    trash: {
      title: 'سطل زباله فاکتور فروش'
    },

    suggest: {
      title: 'پیشنهاد فروش',

      table: {
        header: {
          row: 'ردیف',
          image: 'عکس',
          barcode: 'بارکد',
          code: 'کد',
          category: 'دسته بندی',
          name: 'عنوان',
          variant: 'متغیر',
          score: 'امتیاز',
          totalSold: 'فروش کل',
          saleInventory: 'موجودی'
        }
      }
    },

    refreshStatus: {
      title: 'برگرداندن وضعیت فاکتور فروش',

      description: 'درنظر داشته باشید که در صورت برگرداندن وضعیت فاکتور، کلیه اسناد و حواله های مربوط به هر وضعیت پاک می شود',

      confirm: 'تایید',
      cancel: 'انصراف'
    },

    activeInvoiceExist: {
      title: 'جهت اعمال تغییرات ابتدا باید وضعیت فاکتورهای زیر به پیش فاکتور تغییر کند',

      table: {
        header: {
          id: 'شماره فاکتور',
          type: 'نوع',
          user: 'طرف حساب',
          date: 'تاریخ',
          status: 'وضعیت'
        }
      },

      invoiceTypes: {
        1: 'فروش',
        2: 'خرید',
        3: 'مرجوع فروش',
        4: 'مرجوع خرید'
      }
    }
  },

  returnInvoices: {
    title: 'فاکتورهای مرجوع فروش',

    labels: {
      user: 'طرف حساب',
      id: 'شناسه فاکتور',
      sourceInvoice: 'منبع',
      date: 'تاریخ ثبت',
      status: 'وضعیت'
    },

    placeholders: {
      id: 'اتوماتیک'
    },

    status: {
      all: 'همه',
      preInvoice: 'پیش فاکتور مرجوع فروش',
      invoice: 'فاکتور مرجوع فروش',
      storeReceived: 'رسید انبار',
      incomplete: 'ناتمام'
    },

    actionTypes: {
      present: 'مرجوع فروش حضوری',
      tel: 'مرجوع فروش تلفنی',
      internet: 'مرجوع فروش اینترنتی'
    },

    notifications: {
      document: {
        success: 'سند فاکتور مورد نظر با موفقیت ثبت شد',
        error: 'ثبت سند فاکتور مورد نظر با خطا همراه شد',
        isExist: 'سند فاکتور مورد نظر ثبت شده است'
      },

      insert: {
        success: 'فاکتور مرجوعی فروش با موفقیت ثبت شد',
        error: 'ثبت فاکتور مرجوعی فروش با خطا همراه شد!'
      },

      edit: {
        success: 'فاکتور مرجوعی فروش با موفقیت ویرایش شد',
        error: 'ویرایش فاکتور مرجوعی فروش با خطا همراه شد!'
      },

      delete: {
        success: 'فاکتور با موفقیت حذف شد',
        error: 'حذف فاکتور مرجوع فروش با خطا همراه شد'
      },

      restore: {
        success: 'فاکتور با موفقیت بازگردانی شد',
        error: 'بازگردانی فاکتور مرجوع فروش با خطا همراه شد'
      },

      refreshStatus: {
        success: 'وضعیت فاکتور با موفقیت برگردانده شد',
        error: 'برگرداندن وضعیت فاکتور با خطا شد'
      },

      parseError: {
        user: 'تامین کننده انتخاب نشده است!',
        lines: 'هیچ کالایی انتخاب نشده است!',
        store: 'انبار انتخاب نشده است!',
        unitPrice: 'مبلغ کالا وارد نشده است!',
        invoiceNumber: 'این شماره فاکتور از قبل انتخاب شده است!',
        count: 'تعداد کالای انتخاب شده معتبر نیست!',
        quantity: 'موجودی کالاها کافی نیست!',
        dynamicQuantity: 'کالای {name} قبلا مرجوع شده است و نمیتواند دوباره مرجوع شود!'
      }
    },

    confirmations: {
      delete: {
        title: 'تائیدیه حذف فاکتور مرجوع فروش',
        body: 'در صورت حذف فاکتور مرجوع فروش تمامی اسناد و حواله های انبار مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
      },
      forceDelete: {
        title: 'تائیدیه حذف فاکتور مرجوع فروش',
        body: 'در صورت حذف فاکتور مرجوع فروش اطلاعات فاکتور قابل برگشت نیست. آیا اطمینان دارید؟'
      },
      forceUpdate: {
        title: 'تائیدیه ویرایش فاکتور مرجوع فروش',
        body: 'با ویرایش این فاکتور تمامی اسناد و حواله های انبار ثبت شده، حذف می شوند و سپس اسناد و حواله های جدید صادر می شوند. آیا از ادامه فرآیند اطمینان دارید؟'
      }
    },

    list: {
      title: 'فاکتورهای مرجوع فروش',

      table: {
        header: {
          id: 'شماره فاکتور',
          receivedId: 'شماره فاکتور مرجع',
          creator: 'ایجادکننده',
          user: 'طرف حساب',
          country: 'کشور',
          province: 'استان',
          date: 'تاریخ',
          totalPrice: 'مبلغ کل',
          totalDiscount: 'تخفیف',
          totalVat: 'مالیات',
          shipping: 'حمل و نقل',
          finalPrice: 'مبلغ نهایی',
          type: 'نوع فروش',
          status: 'وضعیت',
          restore: 'بازگردانی',
          delete: 'حذف'
        },

        filters: {
          type: {
            all: 'همه',
            present: 'فروش حضوری',
            tel: 'فروش تلفنی',
            internet: 'فروش اینترنتی'
          }
        }
      }
    },

    insert: {
      title: 'افزودن فاکتور مرجوع فروش',

      typeTitle: {
        present: 'افزودن فاکتور مرجوع فروش حضوری',
        tel: 'افزودن فاکتور مرجوع فروش تلفنی',
        internet: 'افزودن فاکتور مرجوع فروش اینترنتی'
      },

      table: {
        header: {
          rowNumber: 'ردیف',
          barcode: 'بارکد',
          name: 'کالا',
          variant: 'متغیر',
          count: 'تعداد',
          tax: 'مالیات',
          unitPrice: 'مبلغ واحد',
          totalPrice: 'مبلغ کل',
          discount: 'تخفیف کل',
          unitDiscount: 'تخفیف واحد',
          netUnit: 'خالص واحد',
          subTotal: 'مبلغ کل',
          finalPrice: 'مبلغ نهایی',
          store: 'انبار',
          delete: 'حذف'
        }
      }
    },

    invoice: {
      title: 'فاکتور مرجوع فروش',
      dynamicTitle: '{pre} فاکتور مرجوع فروش {type} شماره {id}',
      dynamicTitleWithoutId: 'فاکتور مرجوع فروش {type}',

      table: {
        header: {
          rowNumber: 'ردیف',
          code: 'بارکد',
          name: 'کالا',
          variant: 'متغیر',
          tax: 'مالیات',
          discount: 'تخفیف کل',
          unitDiscount: 'تخفیف واحد',
          store: 'انبار',
          count: 'تعداد',
          unitPrice: 'مبلغ واحد',
          netUnit: 'خالص واحد',
          subTotal: 'مبلغ کل',
          totalPrice: 'مبلغ کل'
        }
      },

      logs: {
        title: 'لاگ های فاکتور مرجوع فروش',
      },

      print: {
        title: 'فاکتور مرجوع فروش',
        preInvoice: 'پیش فاکتور مرجوع',
        taxInvoice: 'فاکتور مرجوع مالیاتی',
      }
    },

    edit: {
      title: 'ویرایش فاکتور مرجوع فروش',

      typeTitle: {
        present: 'ویرایش فاکتور مرجوع فروش حضوری',
        tel: 'ویرایش فاکتور مرجوع فروش تلفنی',
        internet: 'ویرایش فاکتور مرجوع فروش اینترنتی'
      },

      table: {
        header: {
          rowNumber: 'ردیف',
          barcode: 'بارکد',
          name: 'کالا',
          variant: 'متغیر',
          count: 'تعداد',
          tax: 'مالیات',
          unitPrice: 'مبلغ واحد',
          totalPrice: 'مبلغ کل',
          discount: 'تخفیف کل',
          unitDiscount: 'تخفیف واحد',
          netUnit: 'خالص واحد',
          subTotal: 'مبلغ کل',
          finalPrice: 'مبلغ نهایی',
          store: 'انبار',
          delete: 'حذف'
        }
      }
    },

    trash: {
      title: 'سطل زباله فاکتور مرجوع فروش'
    },

    refreshStatus: {
      title: 'برگرداندن وضعیت فاکتور مرجوع فروش',

      labels: {
        description: 'درنظر داشته باشید که در صورت برگرداندن وضعیت فاکتور، کلیه اسناد و حواله های مربوط به هر وضعیت پاک می شود',
        accept: 'تائید',
        cancel: 'انصراف'
      }
    }
  },

  serviceInvoices: {
    title: 'فاکتورهای خدمت',

    labels: {
      id: 'شناسه فاکتور',
      user: 'طرف حساب',
      address: 'آدرس',
      registryDate: 'تاریخ ثبت',
      status: 'وضعیت',
      store: 'انبار'
    },

    placeholders: {
      id: 'اتوماتیک'
    },

    prompts: {
      receive: {
        title: 'دریافت وجه از مشتری',
        dynamicTitle: 'دریافت وجه از {name}',

        subject: 'فاکتور فروش خدمت شماره {id}',

        actions: {
          payment: 'پرداخت',
          cancel: 'انصراف و مشاهده پیش فاکتور'
        }
      },

      address: {
        insert: {
          title: '',
          dynamicTitle: 'افزودن آدرس جدید برای {name}'
        },

        list: {
          title: '',
          dynamicTitle: 'لیست آدرس های {name}'
        }
      },

      users: {
        list: {
          title: 'لیست کاربران'
        },
        insert: {
          title: 'افزودن کاربر جدید'
        }
      }
    },

    actionTypes: {
      present: 'فاکتور خدمت حضوری',
      tel: 'فاکتور خدمت تلفنی',
      internet: 'فاکتور خدمت اینترنتی'
    },

    processTypes: {
      proformaInvoices: 'پیش فاکتور خدمت',
      invoices: 'فاکتور خدمت',
      storeExit: 'خروج انبار'
    },

    notifications: {
      isExists: {
        message: 'این مقدار در لیست وجود دارد',
        dynamicMessage: '{name} در لیست وجود دارد'
      },

      increaseCount: {
        message: 'تعداد این خدمت اضافه شد',
        dynamicMessage: 'تعداد { name } اضافه شد'
      },

      productNotFount: 'خدمتی با این بارکد پیدا نشد',
      quantity: 'موجودی خدمت کافی نیست!',
      dynamicQuantity: 'موجودی خدمت {name} کافی نیست!',
      lowBalance: 'پیش فاکتور ثبت شد اما به دلیل کمبود اعتبار مشتری جهت تکمیل فاکتور نیاز به دریافت وجه می باشد!',
      discountIsBigger: 'تخفیف خدمت {name} بیشتراز مبلغ کل خدمت است!',

      insert: {
        success: 'فاکتور با موفقیت ثبت شد',
        error: 'ثبت فاکتور با خطا همراه شد!'
      },

      edit: {
        success: 'فاکتور با موفقیت ویرایش شد',
        error: 'ویرایش فاکتور با خطا همراه شد!'
      },

      delete: {
        success: 'فاکتور با موفقیت حذف شد',
        error: 'حذف فاکتور خدمت با خطا همراه شد'
      },

      refreshStatus: {
        success: 'وضعیت فاکتور با موفقیت برگردانده شد',
        error: 'برگرداندن وضعیت فاکتور با خطا شد'
      },


      parseError: {
        user: 'تامین کننده انتخاب نشده است!',
        accountSide: 'طرف حساب انتخاب نشده است!',
        lines: 'هیچ خدمتی انتخاب نشده است!',
        address: 'آدرس به صورت صحیح وارد نشده است!',
        storeroom: 'انبار انتخاب نشده است!',
        unitPrice: 'مبلغ خدمت وارد نشده است!',
        invoiceNumber: 'این شماره فاکتور از قبل انتخاب شده است!',
        quantity: 'تعداد خدمت انتخاب شده معتبر نیست!',
        discount: 'تخفیف وارد شده معتبر نیست!'
      }
    },

    confirmations: {
      delete: {
        title: 'تائیدیه حذف فاکتور خدمت',
        body: 'در صورت حذف فاکتور خدمت تمامی اسناد و حواله های مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
      }
    },

    list: {
      title: 'فاکتورهای خدمت',

      table: {
        header: {
          id: 'شماره فاکتور',
          receivedId: 'شماره فاکتور مرجع',
          creator: 'ایجادکننده',
          user: 'طرف حساب',
          date: 'تاریخ',
          finalPrice: 'مبلغ نهایی',
          type: 'نوع خدمت',
          status: 'وضعیت'
        },

        filters: {
          type: {
            all: 'همه',
            present: 'خدمت حضوری',
            tel: 'خدمت تلفنی',
            internet: 'خدمت اینترنتی'
          },
          status: {
            all: 'همه',
            proformaInvoices: 'پیش فاکتور خدمت',
            invoices: 'فاکتور خدمت',
            storeExit: 'خروج انبار'
          }
        }
      },

      actionTypes: {
        present: 'خدمت حضوری',
        tel: 'خدمت تلفنی',
        internet: 'خدمت اینترنتی'
      }
    },

    insert: {
      title: 'افزودن فاکتور خدمت',

      telTypeTitle: 'افزودن فاکتور خدمت تلفنی',
      presentTypeTitle: 'افزودن فاکتور خدمت حضوری',

      table: {
        header: {
          rowNumber: 'ردیف',
          barcode: 'بارکد',
          name: 'خدمت',
          variant: 'متغیر',
          count: 'تعداد',
          unitPrice: 'مبلغ واحد',
          totalPrice: 'مبلغ کل',
          discount: 'تخفیف کل',
          finalPrice: 'مبلغ نهایی',
          storeroom: 'انبار',
          delete: 'حذف'
        }
      }
    },

    invoice: {
      title: 'فاکتور خدمت',
      dynamicTitle: 'فاکتور خدمت {type} شماره {id}',
      dynamicTitleWithoutId: 'فاکتور خدمت {type}',

      labels: {
        payment: 'پرداخت',
        cancel: 'انصراف'
      },

      table: {
        header: {
          rowNumber: 'ردیف',
          code: 'بارکد',
          name: 'خدمت',
          count: 'تعداد',
          unitPrice: 'مبلغ واحد',
          totalPrice: 'مبلغ کل',
          discount: 'تخفیف',
          finalPrice: 'مبلغ نهایی'
        }
      },

      validators: {
        user: 'تامین کننده انتخاب نشده است!',
        products: 'هیچ خدمتی انتخاب نشده است!',
        store: 'انبار انتخاب نشده است!',
        unitPrice: 'مبلغ خدمت وارد نشده است!',
        invoiceNumber: 'این شماره فاکتور از قبل انتخاب شده است!',
        quantity: 'موجودی خدمت کافی نیست!',
        lowPrice: 'پیش فاکتور ثبت شد اما به دلیل کمبود اعتبار مشتری جهت تکمیل فاکتور نیاز به دریافت وجه می باشد!'
      },

      notifications: {
        complete: {
          success: 'فاکتور با موفقیت ثبت شد',
          error: 'ثبت فاکتور با خطا همراه شد!'
        },

        storeExit: {
          error: 'حواله خروج انبار فاکتور خدمت ثبت نشده است'
        }
      },

      actionTypes: {
        present: 'حضوری',
        tel: 'تلفنی',
        internet: 'اینترنتی'
      }
    },

    edit: {
      title: 'ویرایش فاکتور خدمت',
      dynamicTitle: 'ویرایش فاکتور خدمت {type}',

      labels: {
        first: 'اول',
        second: 'دوم'
      },

      table: {
        header: {
          rowNumber: 'ردیف',
          barcode: 'بارکد',
          name: 'خدمت',
          variant: 'متغیر',
          store: 'انبار',
          count: 'تعداد',
          unitPrice: 'مبلغ واحد',
          totalPrice: 'مبلغ کل',
          discount: 'تخفیف',
          finalPrice: 'مبلغ نهایی',
          delete: 'حذف'
        }
      },

      actionTypes: {
        present: 'حضوری',
        tel: 'تلفنی',
        internet: 'اینترنتی'
      }
    },

    refreshStatus: {
      title: 'برگرداندن وضعیت فاکتور خدمت',

      description: 'درنظر داشته باشید که در صورت برگرداندن وضعیت فاکتور، کلیه اسناد و حواله های مربوط به هر وضعیت پاک می شود',

      statusTypes: {
        preInvoice: 'پیش فاکتور خدمت',
        invoice: 'فاکتور خدمت'
      },

      confirm: 'تائید',
      cancel: 'انصراف'
    }
  },

  groups: {
    title: 'گروه های فروش',

    list: {
      title: 'لیست گروه های فروش'
    },

    insert: {
      title: 'افزودن گروه فروش جدید'
    },

    edit: {
      title: 'ویرایش گروه فروش'
    }
  },

  pricing: {
    title: 'قیمت گذاری',

    table: {

      header: {
        row: 'ردیف',
        code: 'کد',
        name: 'نام',
        dynamic: 'متغیر',
        amount: 'مبلغ',
        date: 'تاریخ ثبت',
        action: 'عملیات'
      },

      content: {

        button: {
          set: 'ثبت'
        }
      }
    }
  },

  discount: {
    title: 'تخفیفات فروش'

  },

  actions: {
    insert: 'افزودن',
    edit: 'ویرایش',
    filters: 'فیلترها'
  }
}
