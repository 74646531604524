export default {
  title: 'خدمت',

  list: {
    title: 'لیست خدمت',

    table: {
      header: {
        row: 'ردیف',
        name: 'عنوان خدمت',
        code: 'کد خدمت',
        barcode: 'بارکد',
        price: 'قیمت',
        image: 'عکس خدمت',
        category: 'دسته بندی',
        creator: 'ایجاد کننده',
        lastModifiedDate: 'تاریخ بروزرسانی'
      },

      filters: {
        status: {
          all: 'همه',
          notConnect: 'عدم اتصال',
          draft: 'پیش نویس',
          waiting: 'در انتظار',
          published: 'منتشر شده',
          personal: 'خصوصی'
        },

        type: {
          all: 'همه',
          simpleProduct: 'خدمت ساده',
          variableProduct: 'خدمت متغیر'
        }
      }
    }
  },

  insert: {
    title: 'افزودن خدمت',

    labels: {
      importData: 'آپلود اول دوره خدمت'
    },

    types: {
      label: 'انتخاب نوع افزودن',
      useral: 'افزودن به یوزرال',
      woocommerce: 'افزودن به {shopName} و یوزرال'
    },

    table: {
      header: {
        inventory: 'موجودی انبار',
        barcode: 'بارکد',
        price: 'قیمت',
        salePrice: 'قیمت حراج',
        delete: 'حذف'
      }
    },

    description: {
      title: 'توضیحات',

      useralDescription: 'اطلاعات این خدمت فقط در سامانه یوزرال ثبت میشود.',
      woocommerceDescription: 'اطلاعات خدمت به صورت مستقیم در فروشگاه شما در وضعیت در حال بازبینی ثبت میشود.',
      notCategorySelected: 'هیچ دسته بندی انتخاب نشده است',
      noAttributesExistForCategory: 'هیچ ویژگی برای این دسته بندی وجود ندارد',
      noAttributeChooseForVariables: 'هیچ ویژگی به عنوان متغیر انتخاب نشده است',
      inventory: 'در این صفحه شما می توانید موجودی کل خدمت را وارد کنید. در صورتی که تعداد موجودی برابر با صفر باشد، محصول ناموجود در نظر گرفته خواهد شد.',
      attribute: 'شما می توانید ویژگی مورد نظر خود را انتخاب و به لیست ویژگی های خدمت اضافه نمایید. در صورتی که خدمت از نوع متغیر باشد، شما میتوانید <b>یکی</b> از ویژگی ها را به عنوان ویژگی متغیر خدمت انتخاب نمایید'
    },

    notifications: {
      type: {
        notSelected: {
          error: 'نوع افزودن انتخاب نشده است'
        }
      },
      notConnectToServer: {
        error: 'به علت عدم اتصال به سایت، قادر به افزودن خدمت نمی باشید'
      },
      imageUpload: {
        success: 'عکس با موفقیت آپلود شد'
      },
      imageRemove: {
        success: 'لوگو با موفقیت حذف شد'
      },
      insertProduct: {
        success: 'اطلاعات خدمت با موفقیت ثبت شد',
        error: 'ثبت اطلاعات خدمت با خطا مواجه شد'
      },
      woocommerceInsertProduct: {
        success: 'اطلاعات خدمت با موفقیت  در فروشگاه ثبت شد',
        error: 'ثبت اطلاعات خدمت با خطا مواجه شد'
      }
    },

    accordionList: {
      brand: {
        title: 'برند مورد نظر را انتخاب نمایید',

        insert: {
          title: 'افزودن برند جدید',
          brand: 'عنوان برند'
        },

        edit: {
          title: 'ویرایش برند',
          category: 'عنوان برند'
        },

        delete: {
          title: 'حذف برند',
          message: 'با حذف این برند، تمامی اطلاعات مرتبط با آن نیز حذف می شود.'
        }
      },

      category: {
        title: 'دسته بندی مورد نظر را انتخاب نمایید',

        insert: {
          title: 'افزودن دسته بندی جدید',
          category: 'عنوان دسته بندی'
        },

        edit: {
          title: 'ویرایش دسته بندی',
          category: 'عنوان دسته بندی'
        },

        delete: {
          title: 'حذف دسته بندی',
          message: 'با حذف این دسته بندی، تمامی اطلاعات مرتبط با آن نیز حذف می شود.'
        }
      },


      attributes: 'ویژگی ها',
      insertAttribute: {
        title: 'افزودن ویژگی جدید',
        attribute: 'عنوان ویژگی',
        values: 'مقادیر'
      },
      editAttribute: {
        title: 'ویرایش ویژگی',
        attribute: 'عنوان ویژگی',
        values: 'مقادیر'
      },

      buttons: {
        accept: 'تائید',
        cancel: 'انصراف'
      },

      alert: {
        title: 'پیغام',
        brandCreated: 'برند با موفقیت ایجاد شد',
        brandUpdated: 'برند با موفقیت ویرایش شد',
        brandDeleted: 'برند با موفقیت حذف شد',
        categoryCreated: 'دسته بندی با موفقیت ایجاد شد',
        categoryUpdated: 'دسته بندی با موفقیت ویرایش شد',
        categoryDeleted: 'دسته بندی با موفقیت حذف شد',
        errorTitle: 'خطا',
        error: 'فرآیند با خطا همراه شد'
      }
    }
  },

  edit: {
    title: 'ویرایش خدمت',
    dynamicTitle: 'ویرایش {name}',

    table: {
      header: {
        inventory: 'موجودی انبار',
        barcode: 'بارکد',
        price: 'قیمت',
        salePrice: 'قیمت حراج',
        delete: 'حذف'
      }
    },

    description: {
      title: 'توضیحات',

      variableDescription: 'شما می توانید از طریق این صفحه، اطلاعات مربوط به هر متغیر را در فروشگاه خود ویرایش کنید.',
      useralDescription: 'اطلاعات این خدمت فقط در سامانه یوزرال ثبت میشود.',
      woocommerceDescription: 'اطلاعات خدمت به صورت مستقیم در فروشگاه شما در وضعیت در حال بازبینی ثبت میشود.',
      notCategorySelected: 'هیچ دسته بندی انتخاب نشده است',
      noAttributesExist: 'هیچ ویژگی برای این دسته بندی وجود ندارد',
      noAttributeChooseForVariables: 'هیچ ویژگی به عنوان متغیر انتخاب نشده است',
      inventory: 'در این صفحه شما می توانید موجودی کل خدمت را وارد کنید. در صورتی که تعداد موجودی برابر با صفر باشد، خدمت ناموجود در نظر گرفته خواهد شد.',
      attribute: 'شما می توانید ویژگی مورد نظر خود را انتخاب و به لیست ویژگی های خدمت اضافه نمایید. در صورتی که خدمت از نوع متغیر باشد، شما میتوانید <b>یکی</b> از ویژگی ها را به عنوان ویژگی متغیر خدمت انتخاب نمایید'
    },

    notifications: {
      type: {
        notSelected: {
          error: 'نوع افزودن انتخاب نشده است'
        }
      },
      notConnectToServer: {
        error: 'به علت عدم اتصال به سایت، قادر به افزودن خدمت نمی باشید'
      },
      imageUpload: {
        success: 'عکس با موفقیت آپلود شد'
      },
      imageRemove: {
        success: 'لوگو با موفقیت حذف شد'
      },
      editProduct: {
        success: 'اطلاعات خدمت با موفقیت ثبت شد',
        error: 'ثبت اطلاعات خدمت با خطا مواجه شد'
      },
      woocommerceEditProduct: {
        success: 'اطلاعات خدمت با موفقیت ویرایش شد',
        error: 'ویرایش اطلاعات خدمت با خطا مواجه شد'
      },
      deleteVariant: {
        success: 'اطلاعات متغیر با موفقیت حذف شد',
        error: 'حذف اطلاعات متغیر با خطا همراه شد'
      },
      deleteService: {
        success: 'اطلاعات خدمت با موفقیت حذف شد',
        error: 'حذف اطلاعات خدمت با خطا همراه شد',
        isUsed: 'به دلیل وجود سند، این خدمت قابل حذف نمی باشد'
      }
    },

    confirmations: {
      removeProduct: {
        title: 'تائیدیه',
        message: 'آیا از حذف این خدمت اطمینان دارید؟'
      },
      removeWoocommerceService: {
        title: 'تائیدیه',
        body: 'آیا از حذف این خدمت اطمینان دارید؟ در صورت تائید، اطلاعات خدمت از فروشگاه شما نیز حذف می شود!'
      },
      changeProductType: {
        title: 'هشدار',
        body: 'در صورت تغییر نوع محصول متغیر به ساده، اطلاعات تمامی متغیرها پاک می شود'
      },
      deleteVariable: {
        title: 'تائیدیه',
        body: 'آیا از حذف این متغیر اطمینان دارید؟ در صورت تائید، اطلاعات خدمت از فروشگاه شما نیز حذف می شود!'
      }
    }
  },

  service: {
    title: 'خدمت',

    labels: {
      withoutPrice: 'بدون قیمت'
    },

    importantData: {
      price: 'قیمت',
      salePrice: 'قیمت حراج',
      category: 'دسته بندی',
      type: 'نوع'
    },

    actions: {
      turnover: 'گردش خدمت',
      attributes: 'ویژگی های خدمت',
      variants: 'متغیر های خدمت',
      logs: 'لیست لاگ ها'
    },


    attributes: {
      title: 'ویژگی های خدمت',
      dynamicTitle: 'ویژگی های {name}',

      table: {
        header: {
          rowNumber: 'ردیف',
          key: 'عنوان ویژگی',
          value: 'مقدار ویژگی'
        }
      }
    },

    turnover: {
      title: 'گردش خدمت',
      dynamicTitle: 'گردش {name}',

      table: {
        header: {
          rowNumber: 'ردیف',
          type: 'نوع حواله',
          date: 'تاریخ',
          variant: 'متغیر',
          destination: 'طرف حساب',
          quantity: 'تعداد',
          unitPrice: 'مبلغ واحد',
          status: 'وضعیت'
        }
      },

      typeMapper: {
        openingBalance: 'اول دوره',
        receive: 'رسید انبار',
        exit: 'خروج انبار',
        transfer: 'انتقالی'
      },

      statusMapper: {
        sent: 'ارسال شده',
        onWay: 'در راه',
        received: 'رسیده به انبار',
        waitingToSend: 'در انتظار ارسال'
      }
    },

    inventory: {
      title: 'موجودی خدمت',
      dynamicTitle: 'موجودی خدمت {name}',

      table: {
        header: {
          rowNumber: 'ردیف',
          storeroom: 'انبار',
          openingInventory: 'اول دوره',
          received: 'رسیده به انبار',
          sent: 'ارسال شده',
          minInventory: 'حداقل موجودی',
          onWay: 'در راه',
          totalInventory: 'موجودی کل',
          waitingToSend: 'در انتظار ارسال',
          saleInventory: 'موجودی قابل فروش'
        }
      }
    },

    variants: {
      title: 'متغیر های خدمت',
      dynamicTitle: 'متغیر های {name}',

      table: {
        header: {
          barcode: 'بارکد',
          price: 'قیمت',
          salePrice: 'قیمت حراج'
        }
      }
    },

    logs: {
      title: 'لاگ های خدمت',
    },


    type: {
      simple: 'خدمت ساده',
      variant: 'خدمت متغیر',
      service: 'خدمت'
    }
  },

  select: {
    title: 'لیست خدمت ها',

    table: {
      header: {
        select: 'انتخاب',
        rowNumber: 'ردیف',
        name: 'عنوان خدمت',
        code: 'کد خدمت',
        barcode: 'بارکد',
        price: 'قیمت',
        image: 'عکس خدمت',
        category: 'دسته بندی',
        type: 'نوع',
        variant: 'متغیر',
        salePrice: 'قیمت حراج',
        quantity: 'موجودی',
        storeroom: 'انبار',
        edit: 'ویرایش'
      },

      filters: {
        type: {
          all: 'همه',
          simple: 'خدمت ساده',
          variant: 'خدمت متغیر'
        }
      }
    }
  },

  trash: {
    title: 'سطل آشغال خدمت',

    table: {
      header: {
        rowNumber: 'ردیف',
        name: 'عنوان خدمت',
        code: 'کد خدمت',
        image: 'عکس خدمت',
        type: 'نوع',
        price: 'قیمت',
        delete: 'حذف',
        restore: 'بازگردانی'
      }
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف خدمت',
        body: 'آیا از حذف دایم {name} اطمینان دارید؟'
      }
    },

    notifications: {
      restore: {
        success: 'خدمت با موفقیت بازگردانده شد',
        error: 'بازگردانی خدمت با خطا همراه شد'
      },
      delete: {
        success: 'خدمت با موفقیت حذف شد',
        error: 'حذف خدمت با خطا همراه شد'
      }
    }
  },

  openingBalance: {
    productList: 'لیست خدمت ها',

    insert: {
      title: 'افزودن اول دوره خدمت'
    },


    productType: {
      simple: 'خدمت ساده',
      variant: 'خدمت متغیر',
      service: 'خدمت'
    },

    labels: {
      count: 'تعداد',
      price: 'قیمت',
      delete: 'حذف اول دوره',
      insertProduct: 'افزودن خدمت جدید',
      importData: 'آپلود فایل اول دوره خدمت'
    },

    table: {
      header: {
        rowNumber: 'ردیف',
        barcode: 'بارکد',
        name: 'خدمت',
        variant: 'متغیر',
        count: 'تعداد',
        unitPrice: 'مبلغ واحد',
        totalPrice: 'مبلغ کل',
        delete: 'حذف'
      }
    },

    validators: {
      valueExist: 'این مقدار در لیست وجود دارد',
      productExist: '{name} در لیست وجود دارد',
      barcode: 'خدمتی با این بارکد موجود نمی باشد و یا در اول دوره وجود دارد',
      lock: 'اول دوره قفل است'
    },

    notifications: {
      insert: {
        success: 'اول دوره انبار با موفقیت ثبت شد',
        error: 'ثبت اول دوره انبار با خطا همراه شد!'
      },
      importData: {
        success: 'آپلود فایل اول دوره با موفقیت انجام شد',
        error: 'درخواست آپلود اول دوره با خطا همراه شد'
      },
      edit: {
        success: 'اول دوره کالای مورد نظر با موفقیت بروزرسانی شد',
        error: 'بروزرسانی اول دوره کالای مورد نظر با خطا همراه شد'
      },
      delete: {
        success: 'اول دوره کالای مورد نظر با موفقیت حذف شد',
        error: 'حذف اول دوره کالای مورد نظر با خطا همراه شد'
      }
    },

    confirmations: {
      delete: {
        title: 'تائیدیه حذف',
        body: 'آیا از حذف اول دوره کالای مورد نظر اطمینان دارید؟'
      }
    }
  },

  labels: {
    type: 'نوع خدمت',
    id: 'شناسه',
    barcode: 'بارکد',
    url: 'آدرس',
    productName: 'نام کالا',
    serviceName: 'نام خدمت',
    category: 'دسته بندی',
    serviceCategory: 'دسته بندی خدمات',
    price: 'قیمت',
    productPrice: 'قیمت کالا',
    servicePrice: 'قیمت خدمت',
    salePrice: 'قیمت حراج',
    saleStartDate: 'تاریخ شروع حراج',
    saleEndDate: 'تاریخ پایان حراج',
    maxSelect: 'حداکثر تعداد انتخاب',
    description: 'توضیحات',
    inventory: 'موجودی انبار',
    chooseAttribute: 'انتخاب ویژگی',
    chooseCategory: 'انتخاب دسته بندی',
    withoutCategory: 'بدون دسته بندی',
    removeService: 'حذف خدمت',
    createVariable: 'ایجاد متغیر جدید',
    removeVariable: 'حذف متغیر'
  },

  validators: {
    dateHelp: 'ورودی باید به صورت {date} وارد شود.',
    wrongInputValues: 'اطلاعات وارد شده صحیح نمی باشد',
    imageSize: 'حجم فایل انتخاب شده بیش از حد مجاز است',
    fileFormat: 'فرمت فایل انتخاب شده مجاز نیست',
    variantExist: 'متغیری با این ویژگی وجود دارد',
    variantListTotalCount: 'تعداد کل حالت های ممکن برابر است با {max}',
    salePrice: 'قیمت حراج نمی تواند از قیمت اصلی بیشتر یا مساوی باشد',
    variableSalePrice: 'قیمت حراج متغیر با بارکد {barcode} نمی تواند از قیمت اصلی بیشتر یا مساوی باشد',
    title: 'کالایی با این نام، وجود دارد و یا نام وارد شده معتبر نیست.',
    code: 'کالایی با این کد، وجود دارد و یا کد اشتباه می باشد',
    barcode: 'کالایی با این بارکد، وجود دارد و یا بارکد اشتباه می باشد',
    variableBarcode: 'بارکد متغیر ردیف {row} تکراری است'
  },

  dataCategory: {
    generalInfo: 'اطلاعات عمومی',
    attributes: 'ویژگی ها',
    variables: 'متغیرها'
  },

  productType: {
    simple: 'ساده',
    variant: 'متغیر',
    service: 'خدمت'
  },

  pricing: {
    title: 'قیمت گذاری محصولات',

    table: {
      header: {
        rowNumber: 'ردیف',
        name: 'عنوان محصول',
        code: 'کد محصول',
        barcode: 'بارکد',
        price: 'قیمت',
        salePrice: 'قیمت حراج',
        image: 'عکس محصول',
        category: 'دسته بندی',
        variant: 'متغیر',
        creator: 'ایجاد کننده',
        type: 'نوع',
        status: 'وضعیت',
        shops: 'فروشگاه ها',
        lastModifiedDate: 'تاریخ بروزرسانی'
      },

      filters: {
        type: {
          all: 'همه',
          simpleProduct: 'کالای ساده',
          variableProduct: 'کالای متغیر'
        }
      }
    },

    notifications: {
      edit: {
        success: 'قیمت های کالای مورد نظر با موفقیت بروز شد',
        error: 'بروزرسانی قیمت های کالای مورد نظر با خطا همراه شد'
      }
    },

    validators: {
      salePrice: 'قیمت حراج نمی تواند از قیمت اصلی بیشتر یا مساوی باشد'
    },

    status: {
      notConnect: 'عدم اتصال',
      draft: 'پیش نویس',
      waiting: 'در انتظار',
      published: 'منتشر شده',
      personal: 'خصوصی'
    }
  },

  brands: {
    title: 'برند ها'
  }
}
