export default {
  title: 'انبارداری',

  stores: {
    title: 'انبار',

    labels: {
      name: 'نام انبار',
      code: 'کد انبار',
      delete: 'حذف انبار'
    },

    list: {
      title: 'لیست انبار',

      table: {
        header: {
          rowNumber: 'ردیف',
          code: 'کد',
          name: 'عنوان',
          balance: 'موجودی کالا'
        }
      }
    },

    insert: {
      title: 'افزودن انبار',

      notifications: {
        insert: {
          success: 'اطلاعات انبار جدید با موفقیت ثبت شد',
          error: 'افزودن انبار جدید با خطا همراه شد'
        }
      }
    },

    edit: {
      title: 'ویرایش انبار',

      notifications: {
        edit: {
          success: 'اطلاعات انبار با موفقیت ویرایش شد',
          error: 'ویرایش اطلاعات انبار با خطا همراه شد'
        }
      }
    },

    store: {
      title: 'انبار',

      importantData: {
        code: 'شناسه انبار'
      },

      actions: {
        storeTransactions: 'گردش مالی',
        storeTurnover: 'گردش کالا',
        storeInventory: 'مرور تعدادی محصولات',
        storeInventoryValuation: 'مرور مبلغی محصولات',
        logs: 'لیست لاگ ها'
      }
    },

    trash: {
      title: 'سطل آشغال انبار',

      table: {
        header: {
          rowNumber: 'ردیف',
          code: 'کد',
          name: 'عنوان انبار',
          delete: 'حذف',
          restore: 'بازگردانی'
        }
      },

      confirmations: {
        delete: {
          title: 'تاییدیه حذف انبار',
          body: 'آیا از حذف دایم {name} اطمینان دارید؟'
        }
      },

      notifications: {
        delete: {
          success: 'انبار با موفقیت حذف شد',
          error: 'حذف اطلاعات انبار با خطا همراه شد'
        },
        restore: {
          success: 'انبار با موفقیت بازگردانده شد',
          error: 'بازگردانی انبار با خطا همراه شد'
        }
      }
    },

    delete: {
      confirmations: {
        delete: {
          title: 'تاییدیه حذف انبار',
          body: 'آیا از حذف موقت انبار {name} اطمینان دارید؟'
        }
      },

      notifications: {
        delete: {
          success: 'اطلاعات انبار با موفقیت حذف شد',
          error: 'حذف اطلاعات انبار با خطا همراه شد'
        }
      }
    },

    validators: {
      name: 'نام انبار به صورت صحیح وارد نشده است',
      nameCantEmpty: 'نام انبار نمی تواند خالی باشد',
      code: 'کد انبار به صورت صحیح وارد نشده است',
      codeCantEmpty: 'کد انبار نمی تواند خالی باشد'
    }
  },

  turnover: {
    title: 'گردش کالا',
    dynamicTitle: 'گردش کالا {name}',

    table: {
      header: {
        row: 'ردیف',
        docNumber: 'شماره سند',
        name: 'عنوان محصول',
        image: 'عکس محصول',
        date: 'تاریخ',
        barcode: 'بارکد',
        code: 'کد محصول',
        category: 'دسته بندی',
        variant: 'متغیر',
        storeroom: 'انبار',
        quantity: 'تعداد',
        unitPrice: 'مبلغ واحد',
        description: 'مستندات',
        price: 'مبلغ',
        balance: 'موجودی',
        status: 'وضعیت'
      }
    },

    statusMapper: {
      sent: 'ارسال شده',
      onWay: 'در راه',
      received: 'رسیده به انبار',
      waitingToSend: 'در انتظار ارسال'
    }
  },

  transactions: {
    title: 'گردش مالی',
    dynamicTitle: 'گردش مالی {name}',

    table: {
      header: {
        docNumber: 'شماره سند',
        name: 'عنوان محصول',
        image: 'عکس محصول',
        date: 'تاریخ',
        barcode: 'بارکد',
        code: 'کد محصول',
        category: 'دسته بندی',
        variant: 'متغیر',
        quantity: 'تعداد',
        unitPrice: 'مبلغ واحد',
        description: 'مستندات',
        price: 'مبلغ',
        balance: 'موجودی',
        status: 'وضعیت'
      }
    },

    statusMapper: {
      sent: 'ارسال شده',
      onWay: 'در راه',
      received: 'رسیده به انبار',
      waitingToSend: 'در انتظار ارسال'
    }
  },

  logs: {
    title: 'لاگ های انبار',
  },

  inventory: {
    title: 'مرور تعدادی انبار',
    dynamicTitle: 'مرور تعدادی انبار {name}',

    tabs: {
      inventory: 'ریز موجودی کالا',
      turnover: 'گردش انبار کالا'
    },

    table: {
      header: {
        rowNumber: 'ردیف',
        image: 'عکس محصول',
        barcode: 'بارکد',
        code: 'کد محصول',
        category: 'دسته بندی',
        name: 'عنوان محصول',
        variant: 'متغیر',
        openingInventory: 'اول دوره',
        received: 'رسیده به انبار',
        minInventory: 'حداقل موجودی',
        totalInventory: 'موجودی کل',
        sent: 'ارسال شده',
        onWay: 'در راه',
        waitingToSend: 'در انتظار ارسال',
        saleInventory: 'موجودی قابل فروش'
      }
    },

    notifications: {
      export: {
        success: 'درخواست دریافت خروجی مرور تعدادی انبار با موفقیت انجام شد، لطفا منتظر بمانید.',
        error: 'درخواست دریافت خروجی مرور تعدادی انبار با خطا همراه شد.'
      }
    }
  },

  inventoryValuation: {
    title: 'مرور مبلغی انبار',
    dynamicTitle: 'مرور مبلغی انبار {name}',

    table: {
      header: {
        rowNumber: 'ردیف',
        image: 'عکس محصول',
        barcode: 'بارکد',
        code: 'کد محصول',
        category: 'دسته بندی',
        name: 'عنوان محصول',
        variant: 'متغیر',
        openingInventory: 'اول دوره',
        received: 'رسیده به انبار',
        minInventory: 'حداقل موجودی',
        totalInventory: 'موجودی کل',
        sent: 'ارسال شده',
        onWay: 'در راه',
        waitingToSend: 'در انتظار ارسال',
        saleInventory: 'موجودی قابل فروش'
      }
    }
  },

  receipts: {
    title: 'حواله های انبار',

    list: {
      title: 'لیست حواله های انبار',

      storeName: 'انبار {name}',

      table: {
        header: {
          id: 'شماره حواله',
          creator: 'ایجادکننده',
          accountSide: 'طرف حساب',
          invoiceId: 'شناسه فاکتور',
          type: 'نوع',
          approved: 'وضعیت',
          createdAt: 'تاریخ ثبت'
        },

        filters: {
          type: {
            all: 'همه',
            receive: 'رسید',
            transfer: 'انتقالی',
            deficit: 'کسورات',
            surplus: 'اضافات',
            conversion: 'تبدیلات',
            exit: 'خروج'
          },

          approved: {
            all: 'همه',
            confirm: 'تائید شده',
            pending: 'در انتظار'
          }
        }
      },

      receiptTypes: {
        receive: 'رسید',
        transfer: 'انتقالی',
        deficit: 'کسورات',
        surplus: 'اضافات',
        conversion: 'تبدیلات',
        exit: 'خروج'
      },

      statusTypes: {
        confirm: 'تائید شده',
        pending: 'در انتظار'
      }
    },

    insert: {
      title: 'افزودن حواله انبار',

      labels: {
        type: 'نوع حواله',
        store: 'انبار',
        originStore: 'انبار مبدا',
        destinationStore: 'انبار مقصد',
        registryDate: 'تاریخ ثبت',
        insertProduct: 'افزودن کالای جدید',
        first: 'اول',
        second: 'دوم'
      },

      table: {
        header: {
          barcode: 'بارکد',
          name: 'کالا',
          variant: 'متغیر',
          count: 'تعداد',
          unitPrice: 'مبلغ واحد',
          totalPrice: 'مبلغ کل',
          storeroom: 'انبار مبدا',
          destinationStoreroom: 'انبار مقصد',
          delete: 'حذف'
        }
      },

      receiptTypes: {
        receive: 'رسید انبار',
        transfer: 'انتقالی انبار',
        exit: 'خروج انبار'
      }
    },

    edit: {
      title: 'ویرایش حواله انبار'
    },

    receipt: {
      title: 'حواله انبار',
      dynamicTitle: 'حواله {name}',

      labels: {
        user: 'طرف حساب',
        supplier: 'تامین کننده',
        orderId: 'شماره سفارش',
        price: 'ارزش حدودی',
        registryDate: 'تاریخ ثبت',
        orderDate: 'تاریخ سفارش',
        phoneNumber: 'شماره تماس',
        type: 'نوع',
        status: 'وضعیت',
        note: 'توضیحات',
        signature: 'امضاء',
        receiverName: 'نام تحویل گیرنده',
        senderName: 'نام تحویل دهنده',
        print: 'پرینت',
        back: 'بازگشت'
      },

      prompts: {
        noteDialog: {
          title: 'توضیحات مشتری برای فاکتور'
        }
      },

      table: {
        header: {
          rowNumber: 'ردیف',
          image: 'عکس محصول',
          code: 'بارکد',
          name: 'شرح کالا',
          store: 'انبار',
          originStore: 'انبار مبدا',
          destinationStore: 'انبار مقصد',
          count: 'تعداد',
          unitPrice: 'مبلغ واحد',
          totalPrice: 'مبلغ کل'
        }
      },

      validators: {
        count: 'تعداد {name} با تعداد این کالا در فاکتور مغایرت دارد',
        scan: ' {name} اسکن نشده است'
      },

      confirmation: {
        confirm: {
          title: 'تائیدیه حواله {name}',
          body: 'آیا از {name} حواله فوق اطمینان دارید؟'
        },
        delete: {
          title: 'تائیدیه حذف حواله {name}',
          body: 'آیا از حذف حواله {name} حواله فوق اطمینان دارید؟'
        }
      },

      receiptTypes: {
        openingInventory: 'اول دوره',
        receive: 'رسید',
        transfer: 'انتقالی',
        deficit: 'کسورات',
        surplus: 'اضافات',
        conversion: 'تبدیلات',
        exit: 'خروج'
      },

      receiptActions: {
        receive: 'رسید',
        transfer: 'انتقال',
        exit: 'خروج'
      },

      status: {
        confirm: 'تائیدشده',
        notConfirm: 'عدم تائید'
      },

      postalLabel: {
        title: 'برچسب پستی',
        dynamicTitle: 'برچسب پستی فاکتور فروش شماره {number}',

        theme1: {
          orderNumber: 'شماره سفارش:',
          shipDate: 'تاریخ سفارش: ',
          customer: 'مشتری: ',
          shipTo: 'گیرنده: ',
          shipFrom: 'فرستنده: ',
          receiverInfo: 'اطلاعات گیرنده',
          senderInfo: 'اطلاعات فرستنده',
          receiverName: 'نام گیرنده',
          senderName: 'عنوان',
          address: 'آدرس',
          phoneNumber: 'تلفن',
          poBox: 'کد پستی',
          callBeforeSend: 'لطفا قبل از ارسال تماس بگیرید',
          note: 'توضیحات: ',
          insurancePrice: 'مبلغ بیمه'
        },

        theme2: {
          orderNumber: 'شماره سفارش:',
          shipDate: 'تاریخ ارسال: ',
          customer: 'مشتری: ',
          shipTo: 'گیرنده: ',
          note: 'یادداشت',
          shipFrom: 'فرستنده: ',
          poBox: 'کد پستی',
          logesticSupport: 'پشتیبانی : ',
          customerService: 'خدمات پس از فروش : '
        },

        actions: {
          print: 'پرینت',
          back: 'بازگشت'
        }
      },

      logs: {
        title: 'لاگ های حواله انبار',
      }
    },

    receiptConfirm: {
      table: {
        header: {
          rowNumber: 'ردیف',
          barcode: 'بارکد',
          name: 'کالا',
          count: 'تعداد',
          delete: 'حذف'
        }
      },

      validators: {
        notFound: 'کالایی با این بارکد پیدا نشد',
        notExist: 'کالایی با این بارکد در فاکتور وجود ندارد',
        noProductChoose: 'هیچ کالایی انتخاب نشده است'
      },

      notifications: {
        insertProduct: {
          success: 'تعداد این کالا اضافه شد'
        },

        dynamicInsertProduct: {
          success: 'تعداد { name } اضافه شد'
        }
      }
    },

    validators: {
      store: 'انبار انتخاب نشده است!',
      originStore: 'انبار مبدا انتخاب نشده است',
      destinationStore: 'انبار مقصد انتخاب نشده است',
      valuesExist: 'این مقدار در لیست وجود دارد',
      productExist: '{name} در لیست وجود دارد',
      noProductChoose: 'هیچ کالایی انتخاب نشده است',
      barcode: 'کالایی با این بارکد پیدا نشد',
      price: 'مبلغ کالا وارد نشده است!',
      productCount: 'موجودی کالاها کافی نیست!',
      dynamicProductCount: 'موجودی کالای {name} کافی نیست!',
      quantity: 'تعداد کالای وارد شده معتبر نیست!'
    },

    notifications: {
      insert: {
        success: 'حواله انبار با موفقیت ثبت شد',
        error: 'ثبت حواله انبار با خطا همراه شد!'
      },

      delete: {
        success: 'حواله انبار با موفقیت حذف شد',
        error: 'حذف حواله انبار با خطا همراه شد!'
      },

      confirm: {
        success: 'حواله انبار با موفقیت تائید شد',
        error: 'تائید حواله انبار با خطا همراه شد'
      }
    }
  },

  deficit: {
    title: 'کسورات و اضافات انبار',

    table: {
      header: {
        row: 'ردیف',
        date: 'تاریخ',
        description: 'توضیحات',
        type: 'نوع',
        status: 'وضعیت',
        creator: 'ایجاد کننده',
        barcode: 'بارکد',
        product: 'کالا',
        warehouse: 'انبار',
        count: 'تعداد',
        delete: 'حذف',
        restore: 'بازگردانی'
      }
    },

    labels: {
      allProduct: 'همه محصولات',
      warehouse: 'انبار',
      id: 'شناسه فاکتور',
      registryDate: 'تاریخ ثبت',
      type: 'نوع',
      status: 'وضعیت'
    },

    notifications: {
      isExists: {
        message: 'این مقدار در لیست وجود دارد',
        dynamicMessage: '{name} در لیست وجود دارد'
      },

      increaseCount: {
        message: 'تعداد این کالا اضافه شد',
        dynamicMessage: 'تعداد { name } اضافه شد'
      },

      productNotFount: 'کالایی با این بارکد پیدا نشد',
      quantity: 'موجودی کالاها کافی نیست!',
      dynamicQuantity: 'موجودی کالای {name} کافی نیست!',

      insert: {
        deficit: {
          success: 'کسورات انبار با موفقیت ثبت شد',
          error: 'ثبت کسورات انبار با خطا همراه شد!'
        },
        surplus: {
          success: 'اضافات انبار با موفقیت ثبت شد',
          error: 'ثبت اضافات انبار با خطا همراه شد!'
        }
      },

      edit: {
        deficit: {
          success: 'کسورات انبار با موفقیت ویرایش شد',
          error: 'ویرایش کسورات انبار با خطا همراه شد!'
        },
        surplus: {
          success: 'اضافات انبار با موفقیت ویرایش شد',
          error: 'ویرایش اضافات انبار با خطا همراه شد!'
        }
      },

      delete: {
        deficit: {
          success: 'کسورات انبار با موفقیت حذف شد',
          error: 'حذف کسورات انبار با خطا همراه شد'
        },
        surplus: {
          success: 'اضافات انبار با موفقیت حذف شد',
          error: 'حذف اضافات انبار با خطا همراه شد'
        }
      },

      restore: {
        deficit: {
          success: 'کسورات انبار با موفقیت بازگردانی شد',
          error: 'بازگردانی کسورات انبار با خطا همراه شد'
        },
        surplus: {
          success: 'اضافات انبار با موفقیت بازگردانی شد',
          error: 'بازگردانی اضافات انبار با خطا همراه شد'
        }
      },

      parseError: {
        storeroom: 'انبار انتخاب نشده است!',
        stocks: 'هیچ کالایی انتخاب نشده است!',
        description: 'توضیحات وارد نشده است!',
        invoiceNumber: 'این شماره فاکتور از قبل انتخاب شده است!',
        quantity: 'تعداد کالای انتخاب شده معتبر نیست!',
        noProductEnter: 'هیچ کالای انتخاب نشده است!'
      }
    },

    confirmations: {
      delete: {
        deficit: {
          title: 'تائیدیه حذف کسورات انبار',
          body: 'در صورت حذف کسورات انبار تمامی اسناد و حواله های انبار مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
        },
        surplus: {
          title: 'تائیدیه حذف اضافات انبار',
          body: 'در صورت حذف اضافات انبار تمامی اسناد و حواله های انبار مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
        }
      },
      forceDelete: {
        title: 'تائیدیه حذف فاکتور',
        body: 'در صورت حذف فاکتور اطلاعات فاکتور قابل برگشت نیست. آیا اطمینان دارید؟'
      },
      forceUpdate: {
        deficit: {
          title: 'تائیدیه ویرایش کسورات انبار',
          body: 'با ویرایش کسورات انبار تمامی اسناد و حواله های انبار ثبت شده، حذف می شوند و سپس اسناد و حواله های جدید صادر می شوند. آیا از ادامه فرآیند اطمینان دارید؟'
        },
        surplus: {
          title: 'تائیدیه ویرایش اضافات انبار',
          body: 'با ویرایش اضافات انبار تمامی اسناد و حواله های انبار ثبت شده، حذف می شوند و سپس اسناد و حواله های جدید صادر می شوند. آیا از ادامه فرآیند اطمینان دارید؟'
        }
      }
    },

    types: {
      deficit: 'کسورات',
      surplus: 'اضافات'
    },

    statusTypes: {
      draft: 'پیش نویس',
      confirmed: 'تایید شده'
    },

    list: {
      title: 'کسورات و اضافات انبار'
    },

    trash: {
      title: 'سطل زباله کسورات و اضافات انبار'
    },

    insert: {
      title: 'افزودن کسورات انبار',
      dynamicTitle: {
        deficit: 'افزودن کسورات انبار',
        surplus: 'افزودن اضافات انبار'
      }
    },

    profile: {
      title: 'پروفایل کسورات انبار',
      dynamicTitle: {
        deficit: 'پروفایل کسورات انبار',
        surplus: 'پروفایل اضافات انبار'
      }
    },

    edit: {
      title: 'ویرایش کسورات انبار',
      dynamicTitle: {
        deficit: 'ویرایش کسورات انبار',
        surplus: 'ویرایش اضافات انبار'
      }
    },

    logs: {
      title: 'لاگ های فاکتور',
    },
  },

  conversion: {
    title: 'حواله تبدیلات کالا',

    table: {
      header: {
        row: 'ردیف',
        date: 'تاریخ',
        description: 'توضیحات',
        invoiceId: 'شماره فاکتور',
        status: 'وضعیت',
        creator: 'ایجاد کننده',
        sourceBarcode: 'بارکد مبدا',
        sourceName: 'کالای مبدا',
        sourceCount: 'تعداد مبدا',
        destinationBarcode: 'بارکد مقصد',
        destinationName: 'کالای مقصد',
        rate: 'نرخ تبدیل',
        warehouse: 'انبار',
        delete: 'حذف',
        restore: 'بازگردانی'
      }
    },

    labels: {
      allProduct: 'همه محصولات',
      warehouse: 'انبار',
      id: 'شناسه فاکتور',
      registryDate: 'تاریخ ثبت',
      type: 'نوع',
      status: 'وضعیت'
    },

    notifications: {
      isExists: {
        message: 'این مقدار در لیست وجود دارد',
        dynamicMessage: '{name} در لیست وجود دارد'
      },

      increaseCount: {
        message: 'تعداد این کالا اضافه شد',
        dynamicMessage: 'تعداد { name } اضافه شد'
      },

      productNotFount: 'کالایی با این بارکد پیدا نشد',
      quantity: 'موجودی کالاها کافی نیست!',
      dynamicQuantity: 'موجودی کالای {name} کافی نیست!',

      insert: {
        success: 'تبدیلات کالا با موفقیت ثبت شد',
        error: 'ثبت تبدیلات کالا با خطا همراه شد!'
      },

      edit: {
        success: 'تبدیلات کالا با موفقیت ویرایش شد',
        error: 'ویرایش تبدیلات کالا با خطا همراه شد!'
      },

      delete: {
        success: 'تبدیلات کالا با موفقیت حذف شد',
        error: 'حذف تبدیلات کالا با خطا همراه شد'
      },

      restore: {
        success: 'تبدیلات کالا با موفقیت بازگردانی شد',
        error: 'بازگردانی تبدیلات کالا با خطا همراه شد'
      },

      parseError: {
        storeroom: 'انبار انتخاب نشده است!',
        stocks: 'هیچ کالایی انتخاب نشده است!',
        description: 'توضیحات وارد نشده است!',
        invoiceNumber: 'این شماره فاکتور از قبل انتخاب شده است!',
        quantity: 'تعداد کالای انتخاب شده معتبر نیست!',
        noProductEnter: 'هیچ کالای انتخاب نشده است!'
      }
    },

    confirmations: {
      delete: {
        title: 'تائیدیه حذف تبدیلات کالا',
        body: 'در صورت حذف تبدیلات کالا تمامی اسناد و حواله های انبار مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
      },
      forceDelete: {
        title: 'تائیدیه حذف فاکتور',
        body: 'در صورت حذف فاکتور اطلاعات فاکتور قابل برگشت نیست. آیا اطمینان دارید؟'
      },
      forceUpdate: {
        title: 'تائیدیه ویرایش تبدیلات کالا',
        body: 'با ویرایش تبدیلات کالا تمامی اسناد و حواله های انبار ثبت شده، حذف می شوند و سپس اسناد و حواله های جدید صادر می شوند. آیا از ادامه فرآیند اطمینان دارید؟'
      }
    },

    types: {
      deficit: 'کسورات',
      surplus: 'اضافات'
    },

    statusTypes: {
      draft: 'پیش نویس',
      confirmed: 'تایید شده'
    },

    list: {
      title: 'حواله تبدیلات کالا',
    },

    trash: {
      title: 'سطل زباله تبدیلات کالا'
    },

    insert: {
      title: 'افزودن تبدیلات کالا'
    },

    profile: {
      title: 'پروفایل تبدیلات کالا'
    },

    edit: {
      title: 'ویرایش تبدیلات کالا'
    },

    logs: {
      title: 'لاگ های تبدیلات کالا',
    },
  },

  received: {
    title: 'رسید انبار'
  },

  transferred: {
    title: 'انقالی انبار'
  },

  sended: {
    title: 'خروج انبار'
  }
}
