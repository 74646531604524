export default {
  title: 'اشخاص',

  labels: {
    user: 'شخص'
  },

  gender: {
    man: 'آقا',
    woman: 'خانم',
    company: 'شرکت'
  },

  types: {
    man: 'آقای',
    woman: 'خانم',
    company: 'شرکت'
  },

  characters: {
    shareholder: 'سهامدار',
    employee: 'کارمند',
    intermediary: 'واسطه',
    supplier: 'تامین کننده',
    customer: 'مشتری'
  },

  notifications: {
    parseError: {
      phoneNumber: 'شماره موبایل قبلا در سامانه ثبت شده سات'
    }
  },

  list: {
    title: 'لیست اشخاص',

    active: 'فعال',
    customer: 'مشتری',
    presence: 'حضوری',

    table: {
      header: {
        row: 'ردیف',
        select: 'انتخاب',
        registryDate: 'تاریخ عضویت',
        phoneNumber: 'تلفن همراه',
        gender: 'جنسیت',
        name: 'نام و نام خانوادگی',
        score: 'امتیاز',
        group: 'گروه',
        company: 'شرکت',
        accessGroup: 'گروه کاربری',
        maxDebt: 'سقف بدهکاری',
        balance: 'کیف پول',
        address: 'آدرس ها',
        access: 'دسترسی ها',
        status: 'وضعیت',
        shops: 'فروشگاه ها',
        deleteAction: 'حذف'
      }
    }
  },

  trash: {
    title: 'سطل زباله اشخاص',

    table: {
      header: {
        restoreAction: 'بازگردانی',
        deleteAction: 'حذف دائم',
        address: 'آدرس ها',
        maxDebt: 'سقف بدهکاری',
        balance: 'کیف پول',
        phoneNumber: 'شماره همراه',
        name: 'نام و نام خانوادگی',
        registryDate: 'تاریخ عضویت',
        row: 'ردیف'
      }
    },

    confirmations: {
      deleteUser: {
        title: 'تاییدیه حذف کاربر',
        body: 'آیا از حذف دائم {userName} اطمینان دارید؟'
      }
    }
  },

  select: {
    title: 'لیست انتخاب اشخاص',

    table: {
      header: {
        address: 'آدرس ها',
        maxDebt: 'سقف بدهکاری',
        balance: 'کیف پول',
        accessGroup: 'گروه کاربری',
        phoneNumber: 'شماره همراه',
        company: 'شرکت',
        name: 'نام و نام خانوادگی',
        registryDate: 'تاریخ عضویت',
        row: 'ردیف',
        select: 'انتخاب'
      }
    }
  },

  openingBalance: {
    type: {
      debtor: 'بدهکاران به شرکت',
      creditor: 'بستانکاران از شرکت'
    },

    labels: {
      importData: 'آپلود فایل اول دوره شخص'
    },

    insert: {
      title: 'افزودن اول دوره {type}',

      notifications: {
        debtor: {
          success: {
            title: 'پیغام',
            message: 'اول دوره اشخاص بدهکار با موفقیت ثبت شد'
          },

          error: {
            title: 'خطا',
            message: 'ثبت اطلاعات اول دوره کاربران بدهکار با خظا همراه شد'
          }
        },

        creditor: {
          success: {
            title: 'پیغام',
            message: 'اول دوره اشخاص بستانکار با موفقیت ثبت شد'
          },

          error: {
            title: 'خطا',
            message: 'ثبت اطلاعات اول دوره کاربران بستانکار با خظا همراه شد'
          }
        },

        locked: {
          title: 'خطا',
          message: 'اول دوره قفل است'
        },

        importData: {
          success: 'آپلود فایل اول دوره با موفقیت انجام شد',
          error: 'درخواست آپلود اول دوره با خطا همراه شد'
        },

        isExists: {
          title: 'خطا',
          message: 'این مقدار در لیست وجود دارد',
          dynamicMessage: '{name} در لیست وجود دارد'
        }
      }
    },

    edit: {
      title: 'ویرایش اول دوره {user}',

      labels: {
        price: 'قیمت',
        delete: 'حذف اول دوره'
      },

      confirmations: {
        delete: {
          title: 'تائیدیه حذف',
          body: 'آیا از حذف اول دوره شخص مورد نظر اطمینان دارید؟'
        }
      },

      notifications: {
        debtor: {
          update: {
            success: {
              title: 'پیغام',
              message: 'اول دوره شخص بدهکار مورد نظر با موفقیت بروزرسانی شد'
            },

            error: {
              title: 'خطا',
              message: 'بروزرسانی اول دوره شخص بدهکار مورد نظر با خطا همراه شد'
            }
          },

          delete: {
            success: {
              title: 'پیغام',
              message: 'اول دوره شخص بدهکار مورد نظر با موفقیت حذف شد'
            },

            error: {
              title: 'خطا',
              message: 'حذف اول دوره شخص بدهکار مورد نظر با خطا همراه شد'
            }
          }
        },

        creditor: {
          update: {
            success: {
              title: 'پیغام',
              message: 'اول دوره شخص بستانکار مورد نظر با موفقیت بروزرسانی شد'
            },

            error: {
              title: 'خطا',
              message: 'بروزرسانی اول دوره شخص بستانکار مورد نظر با خطا همراه شد'
            }
          },

          delete: {
            success: {
              title: 'پیغام',
              message: 'اول دوره شخص بستانکار مورد نظر با موفقیت حذف شد'
            },

            error: {
              title: 'خطا',
              message: 'حذف اول دوره شخص بستانکار مورد نظر با خطا همراه شد'
            }
          }
        }
      }
    },

    list: {
      table: {
        header: {
          delete: 'حذف',
          price: 'مبلغ',
          name: 'نام و نام خانوادگی',
          rowNumber: 'ردیف'
        }
      }
    }
  },

  insert: {
    title: 'افزودن شخص جدید',

    base: 'پایه',

    gender: {
      man: 'آقا',
      woman: 'خانم'
    },

    labels: {
      name: 'نام',
      family: 'نام خانوادگی',
      gender: 'جنسیت',
      companyName: 'نام شرکت',
      phoneNumber: 'شماره تماس',
      email: 'ایمیل'
    },

    validators: {
      company: 'نام شرکت نمی تواند بیش از 50 کاراکتر باشد',
      name: 'نام نمی تواند بیش از 30 کاراکتر باشد',
      nameIsWrong: 'نام به صورت صحیح وارد نشده است',
      nameCantEmpty: 'نام نمی تواند خالی باشد',
      family: 'نام خانوادگی نمی تواند بیش از 50 کاراکتر باشد',
      familyIsWrong: 'نام خانوادگی به صورت صحیح وارد نشده است',
      familyCantEmpty: 'نام خانوادگی نمی تواند خالی باشد',
      phoneNumber: 'فرمت شماره تماس اشتباه است',
      phoneNumberCantEmpty: 'شماره تماس وارد شود',
      email: 'ایمیل نمی تواند بیش از 50 کاراکتر باشد',
      emailIsWrong: 'فرمت ایمیل اشتباه است',
      requiredFieldIsEmpty: 'مقادیر الزامی وارد نشده است',
      duplicatePhoneNumber: 'شماره تماس وارد شده در سامانه موجود می باشد'
    },

    notifications: {
      insert: {
        success: 'اطلاعات با موفقیت ثبت شد'
      }
    }
  },

  edit: {
    title: 'ویرایش اطلاعات ',

    customer: 'مشتری',

    dataCategory: {
      generalInfo: 'اطلاعات عمومی',
      accessLevel: 'سطح دسترسی',
      accessGroup: 'گروه کاربری'
    },

    labels: {
      name: 'نام',
      family: 'نام خانوادگی',
      company: 'شرکت',
      phoneNumber: 'شماره تماس',
      email: 'ایمیل',
      deleteUser: 'حذف کاربر',
      deleteCustomer: 'حذف مشتری'
    },

    validators: {
      inputValuesIsWrong: 'اطلاعات وارد شده صحیح نمی باشد'
    },

    useral: {
      confirmations: {
        delete: {
          title: 'تاییدیه حذف کاربر',
          body: 'آیا از حذف موقت  {name} اطمینان دارید؟'
        }
      },

      notifications: {
        edit: {
          success: 'اطلاعات شخص با موفقیت بروزرسانی شد',
          error: 'بروزرسانی اطلاعات شخص با خطا مواجه شد'
        },
        delete: {
          success: 'اطلاعات شخص مورد نظر حذف شد',
          error: 'حذف اطلاعات شخص با خطا همراه شد'
        }
      }
    },

    woocommerce: {
      customerLogs: 'لاگ های مشتری',

      description: {
        noLogExist: 'هیچ لاگی برای این مشتری وجود ندارد'
      },

      confirmations: {
        delete: {
          title: 'تائیدیه',
          body: 'آیا از حذف اطلاعات این مشتری اطمینان دارید؟ در صورت تائید، اطلاعات مشتری از وبسایت شما نیز حذف می شود!'
        }
      },

      notifications: {
        edit: {
          success: 'اطلاعات مشتری با موفقیت بروزرسانی شد',
          error: 'بروزرسانی اطلاعات مشتری با خطا مواجه شد'
        },
        delete: {
          success: 'اطلاعات شخص مورد نظر حذف شد',
          error: 'حذف اطلاعات مشتری با خطا همراه شد'
        },
        requestSend: {
          success: 'درخواست ارسال شد'
        }
      }
    }
  },

  profile: {
    title: 'پروفایل شخص',
    dynamicTitle: 'پروفایل',

    importantData: {
      phoneNumber: 'شماره تلفن',
      balance: 'کیف پول',
      score: 'امتیاز',
      accessGroup: 'دسترسی',
      company: 'شرکت'
    },

    setMaxDebtModal: {
      title: 'تعیین حداکثر بدهی',
      label: 'حداکثر بدهی شخص',

      notifications: {
        success: {
          title: 'پیغام',
          message: 'حداکثر بدهی شخص با موفقیت بروزرسانی شد'
        },

        error: {
          title: 'خطا',
          message: 'بروزرسانی حداکثر بدهی شخص با خطا مواجه شد'
        }
      }
    },

    addressesModal: {
      title: 'آدرس های'
    },

    transactionsModal: {
      title: 'گردش مالی'
    },

    actions: {
      call: 'تماس',
      whatsApp: 'واتساپ',
      setUserMaxDebt: 'سقف بدهکاری',
      showUserAddresses: 'لیست آدرس ها',
      showUserTransactions: 'گردش مالی',
      insertSaleInvoice: 'فروش',
      insertPurchaseInvoice: 'خرید',
      insertTreasuryReceive: 'دریافت',
      insertTreasuryPayment: 'پرداخت',
      insertTreasuryCost: 'سند هزینه',
      sendMessage: 'ارسال پیام',
      logs: 'لیست لاگ ها'
    }
  },

  transactions: {
    title: 'گردش مالی شخص',
    dynamicTitle: 'گردش مالی',
    documentTitle: 'سند گردش مالی شخص',

    table: {

      header: {
        docNumber: 'شماره سند',
        date: 'تاریخ',
        documentation: 'مستندات',
        price: 'مبلغ',
        balance: 'موجودی'
      }
    },

    types: {
      sale: 'فروش',
      purchase: 'خرید',
      receive: 'دریافت'
    }
  },

  addresses: {
    title: 'آدرس های شخص',
    dynamicTitle: 'آدرس های',

    notifications: {
      edit: {
        success: 'ویرایش آدرس با موفقیت انجام شد',
        error: 'ویرایش آدرس با خطا همراه شد'
      },
      delete: {
        success: 'آدرس با موفقیت حذف شد',
        error: 'حذف آدرس با خطا همراه شد'
      },

      parseError: {
        required: 'مقادیر اجباری وارد نشده اند'
      }
    },

    insert: {
      title: 'افزودن آدرس جدید',

      labels: {
        name: 'عنوان آدرس',
        country: 'کشور',
        province: 'استان',
        city: 'شهر',
        address: 'نشانی پستی',
        postalCode: 'کد پستی',
        receiver: {
          boxTitle: 'اطلاعات گیرنده',
          name: 'نام گیرنده',
          lastName: 'نام خانوادگی گیرنده',
          phoneNumber: 'شماره تماس گیرنده'
        }
      },

      notifications: {
        incorrectDate: {
          title: 'پیغام',
          message: 'اطلاعات به صورت صحیح وارد نشده است'
        },

        success: {
          title: 'پیغام',
          message: 'آدرس جدید با موفقیت برای کاربر ثبت شد'
        },

        error: {
          title: 'خطا',
          message: 'افزودن آدرس جدید کاربر با خطا همراه شد'
        },

        parseError: {
          required: 'مقادیر الزامی وارد نشده است'
        }
      }
    },

    edit: {
      title: 'ویرایش آدرس شخص'
    },

    list: {

      notFountError: 'هیچ آدرسی ثبت نشده است!'
    },

    select: {

      table: {
        header: {
          receiverPhoneNumber: 'شماره تماس دریافت کننده',
          receiverLastName: 'نام خانوادگی دریافت کننده',
          receiverName: 'نام دریافت کننده',
          postalCode: 'کدپستی',
          address: 'آدرس',
          city: 'شهر',
          province: 'استان',
          name: 'عنوان',
          select: 'انتخاب'
        }
      }
    }
  },

  logs: {
    title: 'لاگ های شخص',

    table: {
      header: {
        rowNumber: 'ردیف',
        user: 'کاربر',
        description: 'توضیحات',
        date: 'تاریخ'
      }
    },

    dialogs: {
      title: 'توضیحات',

      noChange: 'فیلدی تغییری داده نشده است'
    }
  },

  actions: {
    insert: 'افزودن',
    edit: 'ویرایش',
    save: 'ثبت',
    delete: 'حذف'
  }
}
