<template>
  <div class="notification-list">
    <ul class="bordered-items">
      <li v-for="(ntf, key) in unreadNotifications"
          :key="ntf.index"
          class="notification cursor-pointer px-4 py-4">

        <div class="notification-header">
          <div class="notification-title">
            <feather-icon :icon="ntf.icon"
                          :svgClasses="[`text-${ntf.category}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>

            <span class="font-medium block notification-title" :class="[`text-${ntf.category}`]">{{
                ntf.title
              }}</span>
          </div>
          <div class="notification-time">{{ ntf.time }}</div>
          <div class="notification-actions">
            <div class="notification-action"
                 @click="selectedEvent = unreadNotificationsSources[ntf.index], selectedIndex = key ,$refs.event.showEvent()">
              <custom-icon icon="EYE" height="20px" width="20px" />
            </div>
            <div class="notification-action"
                 @click="selectedEvent = unreadNotificationsSources[ntf.index], selectedIndex = key ,$refs.event.seenEvent(unreadNotificationsSources[ntf.index], false)">
              <custom-icon icon="TIMES" color="danger" height="20px" width="20px" />
            </div>
          </div>
        </div>
        <div class="notification-content">
          <vue-markdown>
            {{ ntf.msg }}
          </vue-markdown>
        </div>
      </li>
    </ul>

    <custom-event ref="event"
                  :event-data="selectedEvent" @seen="handleSeenNotification"/>

    <button id="showAllEventsBtn" v-show="false" @click="seenAllNotifications(false)"></button>

    <custom-dialog ref="seenAllEventsConfirmation"
                   :title="$t('events.confirmations.seenAll.title')"
                   :body="$t('events.confirmations.seenAll.body', { count: unreadMessageCount })"
                   @accept="seenAllNotifications(true)"/>
  </div>
</template>

<script>
import {getProfileReceivedEvents} from "@/http/requests/profile";
import CustomIcon from "@/components/customIcon/customIcon.vue";
import vueMarkdown from "vue-markdown";
import getLogs from "@/mixins/getLogs";
import CustomEvent from "@/components/customEvent/customEvent.vue";
import CustomDialog from "@/components/customDialog/customDialog.vue";
import {seenAllEvents} from "@/http/requests/events";

export default {
  name: "NotificationList",
  components: {
    CustomDialog,
    CustomEvent,
    CustomIcon,
    vueMarkdown
  },
  mixins: [getLogs],
  props: {
    events: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      selectedIndex: 0,
      selectedEvent: {},
      unreadNotifications: [
        /*{
          index    : 0,
          title    : 'New Message',
          msg      : 'Are your going to meet me tonight?',
          icon     : 'MessageSquareIcon',
          time     : this.randomDate({sec: 10}),
          category : 'primary'
        }*/
      ],
      unreadMessageCount: '',
      unreadNotificationsSources: [],
      iconMapper: {
        1: 'PackageIcon',
        2: 'LayersIcon',
        3: 'MessageSquareIcon',
        4: 'AlertCircleIcon'
      },
      categoryMapper: {
        1: 'success',
        2: 'secondary',
        3: 'primary',
        4: 'warning'
      },
    }
  },
  created () {
    this.getUnReedMessages()
  },
  methods: {
    getUnReedMessages () {
      // getProfileReceivedEvents(1, ['seen=0'], ['order[0]=created_at,desc']).then(response => {
        const events = this.events
        const dynamicEventSubjectKeys = ['woo_controller_user_technical_problem',
          'woo_controller_product_variant_technical_problem', 'woo_controller_product_technical_problem',
          'woo_controller_invoice_technical_problem']
        this.unreadNotificationsSources = events
        this.unreadNotifications = []
        events.forEach((event) => {
          let eventContent = {}
          try {
            eventContent = JSON.parse(event.event.content)
          }
          catch (e) {
            eventContent = event.event.content
          }
          let data = {
            index: this.unreadNotifications.length,
            title: this.getLogSubject(event.event.subject, dynamicEventSubjectKeys.indexOf(event.event.subject) > -1 ? eventContent : {}),
            msg: event.event.content,
            icon: this.iconMapper[event.event.type],
            time: event.event.created_at,
            category: this.categoryMapper[event.event.type]
          }

          if (typeof eventContent === 'object') {
            let contentLogs = []
            Object.values(eventContent.errors || eventContent).forEach((details) => {
              if (details.hasOwnProperty('key')) {
                contentLogs.push(`${this.getErrorLogs(details)}`)
              } else {
                const detail = {
                  ...eventContent,
                  key: event.event.subject
                }
                contentLogs.push(`${this.getErrorLogs(detail)}`)
              }
            })
            data.msg = contentLogs.join(', ')
            this.unreadNotificationsSources[data.index].event = {
              ...this.unreadNotificationsSources[data.index].event,
              subject: data.title,
              content: data.msg
            }
          }

          this.unreadNotifications.push(data)
        })
        this.$emit('eventLoad', this.unreadNotifications.length)
      // })
    },
    handleSeenNotification () {
      this.unreadNotifications.splice(this.selectedIndex, 1)
      if (this.unreadNotifications.length === 0) {
        this.$emit('empty')
      }
      // this.getUnReedMessages()
    },
    seenAllNotifications (force = false) {
      seenAllEvents(force).then((response) => {
        const info = response.data.data
        if (!force) {
          this.unreadMessageCount = info.events_count || ''
          this.$refs.seenAllEventsConfirmation.showDialog()
        } else {
          this.$emit('empty')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-list {
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: block;
    border-radius: .2rem;
    width: 8px;
    background: #f9f9fd;
    right: 8px;
  }

  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: .2rem;
    background: #cecece;
  }

  .notification {
    display: flex;
    flex-direction: column;

    .notification-header {
      display: flex;
      flex-direction: row;

      .notification-title {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
      }

      .notification-time {
        font-size: 12px;
      }

      .notification-actions {
        display: flex;

        .notification-action {
          margin-left: 10px;
        }
      }
    }

    .notification-content {
      margin-top: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      p, span, div {
        width: 100%;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 40px;
      }

      h1, h2, h3, h4, h5, h6, ul, li {
        display: inline-flex;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 40px;
      }
    }
  }
}
</style>
